/* eslint-disable react/prefer-stateless-function */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, withRouter } from 'react-router-dom';

class AuthorizedRoute extends Component {
  render() {
    const { user, path } = this.props;
    if (user.email) {
      return <Route exact path={path} render={(props) => <this.props.component {...props} />} />;
    }
    return <Route exact path={path} render={() => <Redirect to={{ pathname: '/login' }} />} />;
  }
}

AuthorizedRoute.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string,
  }).isRequired,
  path: PropTypes.string.isRequired,
};

const mapStateToProps = ({ user }) => ({
  user,
});

export default withRouter(connect(mapStateToProps)(AuthorizedRoute));
