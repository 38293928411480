import { toaster } from 'evergreen-ui';
import { doc, getDoc } from 'firebase/firestore';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import ReactCardFlip from 'react-card-flip';
import { useTranslation } from 'react-i18next';

import { db } from '../../libs/firebase';
import * as PersonalCard from '../PersonalCard';
import * as RichTextFormatCard from '../RichTextFormatCard';
import Waiting from '../Waiting';

function VirtualViewer({ id, data, links }) {
  const { t } = useTranslation('');
  const [handling, setHandling] = useState(false);
  const [flip, setFlip] = useState(false);
  const [extra, setExtra] = useState();

  useEffect(() => {
    (async () => {
      if ((links || []).length) {
        setHandling(true);
        try {
          const snapshot = await getDoc(doc(db, 'cards', links[0]));
          if (snapshot.exists()) {
            setExtra({ id: links[0], ...snapshot.data() });
          } else toaster.danger(t('common.notexists', { name: links[0] }));
          setHandling(false);
        } catch {
          setHandling(false);
        }
      }
    })();
  }, [links]);

  const Comp = useMemo(() => {
    switch (data.category) {
      case 'personal':
        return PersonalCard.Viewer;
      default:
        return null;
    }
  }, [data.category]);

  const ExtraComp = useMemo(() => {
    if (!extra) return null;
    switch (extra.category) {
      case 'personal':
        return PersonalCard.Viewer;
      case 'rtf':
        return RichTextFormatCard.Viewer;
      default:
        return null;
    }
  }, [extra]);

  return (
    <>
      {handling ? <Waiting /> : null}
      {ExtraComp ? (
        <ReactCardFlip isFlipped={flip} flipDirection="horizontal">
          <Comp id={id} {...data} onNext={() => setFlip(true)} />
          <ExtraComp {...extra} onBack={() => setFlip(false)} />
        </ReactCardFlip>
      ) : (
        <Comp id={id} {...data} />
      )}
    </>
  );
}

VirtualViewer.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  links: PropTypes.arrayOf(PropTypes.string),
};

VirtualViewer.defaultProps = {
  links: [],
};

export default VirtualViewer;
