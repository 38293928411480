import { Pane, TextInputField } from 'evergreen-ui';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

function Info({ onChange, title, fullName, company, phone, email }) {
  const { t } = useTranslation('');

  return (
    <Pane display="flex" flexDirection="column" width="100%">
      <TextInputField
        required
        label={t('cards.fullName')}
        value={fullName}
        onChange={(e) => onChange('fullName', e.target.value)}
      />
      <TextInputField
        label={t('cards.title')}
        value={title}
        onChange={(e) => onChange('title', e.target.value)}
      />
      <TextInputField
        label={t('cards.company')}
        value={company}
        onChange={(e) => onChange('company', e.target.value)}
      />
      <TextInputField
        label="Email"
        value={email}
        onChange={(e) => onChange('email', e.target.value)}
      />
      <TextInputField
        label={t('cards.phone')}
        value={phone}
        onChange={(e) => onChange('phone', e.target.value)}
      />
    </Pane>
  );
}

Info.propTypes = {
  onChange: PropTypes.func.isRequired,
  fullName: PropTypes.string,
  title: PropTypes.string,
  email: PropTypes.string,
  company: PropTypes.string,
  phone: PropTypes.string,
};

Info.defaultProps = {
  fullName: '',
  title: '',
  email: '',
  company: '',
  phone: '',
};

export default Info;
