export const RESOURCES = 'https://resources.back3nd.xyz';

export const SOCIALS = {
  twitter: {
    path: 'twitter.com',
  },
  facebook: {
    path: 'facebook.com',
  },
  youtube: {},
  linkedin: {},
  instagram: {},
  telegram: {
    path: 't.me',
  },
  discord: {},
  whatsapp: {},
  snapchat: {},
  yahoo: {},
  skype: {},
  zalo: {},
};

export const CATEGORIES = ['personal', 'rtf'];
