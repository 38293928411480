import { toaster } from 'evergreen-ui';
import {
  collection,
  deleteDoc,
  deleteField,
  doc,
  getDocs,
  query,
  setDoc,
  where,
} from 'firebase/firestore';
import clone from 'lodash/clone';
import { all, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { db } from '../../libs/firebase';
import { CARDS } from './constants';

function* fetch(data = {}) {
  yield put({
    type: CARDS.fetch,
    data,
  });
}

function* update(id, data) {
  yield put({
    type: CARDS.update,
    id,
    data,
  });
}

function* updateCard({ id, data }) {
  const uid = (yield select())?.user?.uid ?? '';
  try {
    const ref = doc(db, 'cards', id);
    if (data) {
      const clean = clone(data);
      Object.keys(data).forEach((field) => {
        if (data[field] !== false && !data[field]) clean[field] = deleteField();
        if (field === 'socials') {
          Object.keys(data[field]).forEach((social) => {
            if (!data[field][social]) clean[field][social] = deleteField();
          });
        }
      });
      clean.owner = uid;
      yield setDoc(ref, clean, { merge: true });
    } else {
      yield deleteDoc(ref);
    }
    yield* update(id, data);
  } catch ({ message }) {
    toaster.danger(message);
    yield* update();
  }
}

function* getCards() {
  const uid = (yield select())?.user?.uid ?? '';
  try {
    const snapshot = yield getDocs(query(collection(db, 'cards'), where('owner', '==', uid)));
    if (snapshot.empty) yield* fetch();
    else {
      const cards = {};
      snapshot.forEach((value) => {
        cards[value.id] = value.data();
      });
      yield* fetch(cards);
    }
  } catch ({ message }) {
    toaster.danger(message);
    yield* fetch();
  }
}

export const handleUpdateCard = (id, data) => ({
  type: CARDS.handlers.update,
  id,
  data,
});

export const handleGetPages = (id) => ({
  type: CARDS.handlers.pages,
  id,
});

export default function* saga() {
  yield all([
    yield takeLatest(CARDS.handlers.get, getCards),
    yield takeEvery(CARDS.handlers.update, updateCard),
  ]);
}
