import { Pane } from 'evergreen-ui';
import PropTypes from 'prop-types';
import QRCode from 'qrcode.react';
import React, { useState } from 'react';

import { RESOURCES } from '../../config';

function QR({ path, size }) {
  const [showFullScreen, setShowFullScreen] = useState(false);

  const qrcode = (
    <QRCode
      value={`${window.location.origin}/${path}`}
      includeMargin
      size={showFullScreen ? Math.min(400, window.innerWidth - 100) : size}
      imageSettings={{
        src: `${RESOURCES}/inbot.png`,
        height: showFullScreen ? 50 : 10,
      }}
      onClick={() => setShowFullScreen((prev) => !prev)}
    />
  );

  if (showFullScreen) {
    return (
      <Pane
        position="fixed"
        top={0}
        left={0}
        width="100%"
        height="100%"
        backgroundColor="rgba(16, 16, 16, 0.2)"
        display="flex"
        alignItems="center"
        justifyContent="center"
        zIndex={9999}
      >
        {qrcode}
      </Pane>
    );
  }
  return qrcode;
}

QR.propTypes = {
  path: PropTypes.string.isRequired,
  size: PropTypes.number,
};

QR.defaultProps = {
  size: 50,
};

export default QR;
