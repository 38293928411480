import { toaster } from 'evergreen-ui';
import { getAuth, signOut } from 'firebase/auth';
import { all, put, takeEvery, takeLatest } from 'redux-saga/effects';

import { CARDS, SIGN_OUT, USER } from './constants';

export function* update(data = {}) {
  yield put({
    type: USER.update,
    ...data,
  });
}

function userSignedOut() {
  signOut(getAuth());
}

function* userSignedIn(user) {
  const { email, uid } = user;
  if (email) {
    try {
      yield* update({
        uid,
        email,
        displayName: user?.displayName ?? email,
        photoURL: user?.photoURL ?? '',
        authorized: true,
      });
      yield put({
        type: CARDS.handlers.get,
      });
    } catch ({ message }) {
      // yield* update({ authorized: true });
      // yield* userSignedOut();
      toaster.danger(message);
    }
  } else {
    yield* update({ authorized: true });
  }
}

export const handleUserSignedIn = (user) => ({
  type: USER.handlers.login,
  ...user,
});

export const handleUserSignOut = () => ({
  type: SIGN_OUT,
});

export default function* saga() {
  yield all([
    yield takeLatest(USER.handlers.login, userSignedIn),
    yield takeEvery(SIGN_OUT, userSignedOut),
  ]);
}
