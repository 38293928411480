import { cloneDeep, unset } from 'lodash';

import { CARDS, SIGN_OUT } from '../actions/constants';

const initialState = {};

export default function cards(state = initialState, action) {
  const { type, id, data } = action;

  const updated = cloneDeep(state.data);
  switch (type) {
    case CARDS.handlers.get:
      return {
        ...state,
        handling: true,
      };
    case CARDS.fetch:
      return {
        data,
        handling: false,
      };
    case CARDS.update:
      if (id) {
        if (data) updated[id] = { ...data };
        else unset(updated, id);
      }
      return {
        data: updated,
        handling: false,
      };
    case SIGN_OUT:
      return initialState;
    default:
      return state;
  }
}
