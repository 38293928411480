import { EmptyState, LightbulbIcon } from 'evergreen-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';

function Feeds() {
  const { t } = useTranslation('', { keyPrefix: 'common.comingsoon' });
  return (
    <EmptyState
      title={t('title')}
      description={t('description')}
      icon={<LightbulbIcon color="info" />}
      iconBgColor="D6E0FF"
    />
  );
}

export default Feeds;
