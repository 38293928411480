import { ThemeProvider, toaster } from 'evergreen-ui';
import {
  getAuth,
  isSignInWithEmailLink,
  onAuthStateChanged,
  signInWithEmailLink,
} from 'firebase/auth';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Route, Switch as RouterSwitch } from 'react-router-dom';

import AuthorizedRoute from './AuthorizedRoute';
import { AppBar, Waiting } from './components';
import { handleUpdateSettings } from './redux/actions/settings';
import { handleUserSignedIn } from './redux/actions/user';
import refTheme from './Themes';

const Welcome = React.lazy(() => import('./screens/Welcome'));
const Policy = React.lazy(() => import('./screens/Policy'));
const Card = React.lazy(() => import('./screens/Card'));
const Cards = React.lazy(() => import('./screens/Cards'));
const Login = React.lazy(() => import('./screens/Login'));
const Products = React.lazy(() => import('./screens/Products'));

export default function App() {
  const dispatch = useDispatch();
  const { t } = useTranslation('', { keyPrefix: 'login' });
  const { authorized } = useSelector(({ user }) => ({
    authorized: user?.authorized ?? false,
  }));

  useEffect(() => {
    const cached = localStorage.getItem('settings');
    if (cached) {
      const parsed = JSON.parse(cached);
      dispatch(handleUpdateSettings(parsed));
      if (parsed.dark) {
        document.body.style.backgroundColor = '#30404d';
      }
    }
    if (isSignInWithEmailLink(getAuth(), window.location.href)) {
      const email = window.localStorage.getItem('emailForSignIn');
      if (!email) {
        toaster.warning(t('differentDevice'));
      } else {
        signInWithEmailLink(getAuth(), email, window.location.href)
          .then(() => {
            window.localStorage.removeItem('emailForSignIn');
          })
          .catch((err) => {
            toaster.danger(err.message);
          });
      }
    }
    return onAuthStateChanged(getAuth(), (user) => {
      dispatch(handleUserSignedIn(user));
    });
  }, []);

  const ready = useMemo(() => authorized, [authorized]);

  return ready ? (
    <ThemeProvider value={refTheme}>
      <BrowserRouter>
        <AppBar />
        <RouterSwitch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/policy" component={Policy} />
          <Route exact path="/products" component={Products} />
          <Route exact path="/welcome" component={Welcome} />
          <AuthorizedRoute exact path="/cards" component={Cards} />
          <Route path="/" component={Card} />
        </RouterSwitch>
      </BrowserRouter>
    </ThemeProvider>
  ) : (
    <Waiting />
  );
}
