import { Pane, toaster } from 'evergreen-ui';
import PropTypes from 'prop-types';
import React from 'react';
import Dropzone from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { RESOURCES } from '../../../config';

function Cover({ onChange, cover }) {
  const { t } = useTranslation('');

  return (
    <Pane height={105}>
      <Dropzone
        multiple={false}
        onDrop={(files) => {
          if (files.length === 0) return;
          try {
            const reader = new FileReader();
            reader.onerror = ({ message }) => toaster.error(message);
            reader.onload = () => onChange(reader.result);
            reader.readAsDataURL(files[0]);
          } catch {
            toaster.warning(t('warnings.invalidfile'));
          }
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <img src={cover} alt="cover" height={105} width={300} />
          </div>
        )}
      </Dropzone>
    </Pane>
  );
}

Cover.propTypes = {
  onChange: PropTypes.func.isRequired,
  cover: PropTypes.string,
};

Cover.defaultProps = {
  cover: `${RESOURCES}/inbot/thumbs/thumb1.jpg`,
};

export default Cover;
