import { Pane, TextInputField } from 'evergreen-ui';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';

function CardEditor({ data, onChange }) {
  const { t } = useTranslation('');

  const cardHeight = Math.min(window.innerHeight - 250, 530);

  return (
    <Pane
      marginTop={10}
      width="100%"
      display="flex"
      justifyContent="center"
      height={cardHeight}
      overflowY="scroll"
    >
      <Pane display="flex" flexDirection="column" width={300} alignItems="center">
        <TextInputField
          label={t('cards.headline')}
          required
          defaultValue={data.headline || ''}
          onBlur={(e) => onChange(['headline'], e.target.value)}
          width="100%"
        />
        <ReactQuill
          theme="snow"
          value={data.content || ''}
          onChange={(val) => onChange(['content'], val)}
        />
      </Pane>
    </Pane>
  );
}

CardEditor.propTypes = {
  onChange: PropTypes.func.isRequired,
  data: PropTypes.shape({
    headline: PropTypes.string,
    content: PropTypes.string,
  }).isRequired,
};

export default CardEditor;
