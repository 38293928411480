import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

initializeApp({
  apiKey: 'AIzaSyBoXuYdC_cLviqootD-k-ZYRGf-vDfEYRU',
  authDomain: 'inbot-release.firebaseapp.com',
  projectId: 'inbot-release',
  storageBucket: 'inbot-release.appspot.com',
  messagingSenderId: '822582418824',
  appId: '1:822582418824:web:89734f52d276f71390ae3d',
  measurementId: 'G-KD1PX7SS63',
});

const db = getFirestore();

export { db };
