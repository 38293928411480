import {
  Button,
  Dialog,
  EmptyState,
  ErrorIcon,
  InfoSignIcon,
  Pane,
  TickCircleIcon,
  WarningSignIcon,
} from 'evergreen-ui';
import PropTypes from 'prop-types';
import React from 'react';

function Confirmation({ onClose, onConfirm, intent, title, description }) {
  let icon;
  let iconBgColor;
  switch (intent) {
    case 'success':
      icon = <TickCircleIcon color="success" />;
      iconBgColor = '#DCF2EA';
      break;
    case 'warning':
      icon = <WarningSignIcon color="warning" />;
      iconBgColor = '#F8E3DA';
      break;
    case 'danger':
      icon = <ErrorIcon color="danger" />;
      iconBgColor = '#F9DADA';
      break;
    default:
      icon = <InfoSignIcon color="info" />;
      iconBgColor = '#D6E0FF';
  }

  return (
    <Dialog
      onCloseComplete={onClose}
      isShown
      intent={intent}
      hasHeader={false}
      hasFooter={false}
      contentContainerProps={{
        style: { padding: 0 },
      }}
    >
      <EmptyState icon={icon} iconBgColor={iconBgColor} title={title} description={description} />
      <Pane display="flex" justifyContent="end" marginTop={-20} marginBottom={10} marginRight={10}>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          appearance="primary"
          marginLeft={8}
          onClick={() => {
            onClose();
            onConfirm();
          }}
        >
          OK
        </Button>
      </Pane>
    </Dialog>
  );
}

Confirmation.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  intent: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
};

Confirmation.defaultProps = {
  intent: 'none',
  description: '',
};

export default Confirmation;
