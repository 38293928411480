import { Dialog, EmptyState, LockIcon } from 'evergreen-ui';
import PropTypes from 'prop-types';
import React from 'react';

function AccessDenied({ onClose, description }) {
  return (
    <Dialog onCloseComplete={onClose} isShown hasHeader={false} hasFooter={false}>
      <EmptyState
        title="Access Denied!"
        iconBgColor="#F9DADA"
        description={description}
        orientation="vertical"
        icon={<LockIcon color="danger" />}
      />
    </Dialog>
  );
}

AccessDenied.propTypes = {
  onClose: PropTypes.func.isRequired,
  description: PropTypes.string.isRequired,
};

export default AccessDenied;
