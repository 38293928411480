import { Dialog, Pane, Text } from 'evergreen-ui';
import { capitalize } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getSocialsIcon } from '../../../Icons';
import Link from './Link';

function Add({ onClose, onChange, options }) {
  const { t } = useTranslation('');
  const [selected, setSelected] = useState('');

  return (
    <Dialog isShown title={t('common.add')} onCloseComplete={() => onClose()} hasFooter={false}>
      {selected ? (
        <Link
          social={selected}
          value=""
          onClose={() => setSelected('')}
          onChange={(val) => {
            onChange(selected, val);
            onClose();
          }}
        />
      ) : null}
      <Pane display="block">
        {options.map((social) => (
          <Pane
            key={social}
            float="left"
            display="flex"
            flexDirection="column"
            alignItems="center"
            width={70}
            margin={10}
            height={60}
            hoverElevation={3}
            onClick={() => setSelected(social)}
          >
            {getSocialsIcon(social)}
            <Text size={300}>{capitalize(social)}</Text>
          </Pane>
        ))}
      </Pane>
    </Dialog>
  );
}

Add.propTypes = {
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Add;
