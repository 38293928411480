export const USER = {
  handlers: {
    login: 'USER_LOGIN_HANDLER',
  },
  update: 'USER_UPDATE',
};

export const CARDS = {
  handlers: {
    get: 'CARDS_GET_HANDLER',
    pages: 'CARDS_PAGES_HANDLER',
    update: 'CARRDS_UPDATE_HANDLER',
  },
  fetch: 'CARDS_FETCH',
  update: 'CARDS_UPDATE',
};

export const SETTINGS = {
  update: 'UPDATE_SETTINGS',
};

export const SIGN_OUT = 'SIGN_OUT';
