import { Avatar, Button, Heading, Pane, Paragraph, Text } from 'evergreen-ui';
import { saveAs } from 'file-saver';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import vCardJS from 'vcards-js';

import { RESOURCES } from '../../../config';
import { getSocialsIcon, MdEmail, MdPhoneIphone } from '../../Icons';
import QR from '../../QR';

function Info({
  onNext,
  onBack,
  id,
  fullName,
  title,
  company,
  email,
  phone,
  cover,
  avatar,
  headline,
  socials,
  footer,
}) {
  const { t } = useTranslation('', { keyPrefix: 'card' });

  return (
    <Pane
      display="flex"
      flexDirection="column"
      border="default"
      borderRadius={20}
      width={300}
      height={380}
    >
      <Pane position="absolute" padding={10}>
        <QR path={id} />
      </Pane>
      <Pane display="flex" alignItems="center" flexDirection="column">
        <Pane
          height={105}
          overflow="hidden"
          width={300}
          borderTopLeftRadius={20}
          borderTopRightRadius={20}
        >
          <img
            src={cover || `${RESOURCES}/inbot/thumbs/thumb1.jpg`}
            alt="cover"
            height={105}
            width={300}
          />
        </Pane>
        <Avatar src={avatar || ''} color="blue" size={80} marginTop={-40} />
        <Pane
          display="flex"
          borderLeft="default"
          marginTop={5}
          paddingLeft={10}
          flexDirection="column"
        >
          <Heading>{fullName}</Heading>
          <Text>{title}</Text>
          <Text>{company}</Text>
        </Pane>
        <Paragraph margin={10} textAlign="center">
          {headline}
        </Paragraph>
      </Pane>
      <Pane display="flex" justifyContent="center" flexGrow={1}>
        {Object.entries(socials).map(([social, link]) => (
          <Pane
            key={social}
            float="left"
            display="flex"
            flexDirection="column"
            alignItems="center"
            padding={5}
            margin={5}
            borderRadius={50}
            hoverElevation={3}
            onClick={() => window.open(link)}
          >
            {getSocialsIcon(social)}
          </Pane>
        ))}
      </Pane>
      <Pane>
        <Pane width="100%" padding={10}>
          <Pane display="flex" alignItems="center">
            <MdEmail color="#1F3D99" />
            <Text
              paddingLeft={5}
              cursor="pointer"
              onClick={() => {
                window.location = `mailto:${email}`;
              }}
            >
              {email}
            </Text>
          </Pane>
          <Pane display="flex" alignItems="center">
            <MdPhoneIphone color="#1F3D99" />
            <Text
              paddingLeft={5}
              cursor="pointer"
              onClick={() => {
                window.open(`tel:${phone.replace(/[\s-]/gi, '')}`);
              }}
            >
              {phone}
            </Text>
          </Pane>
        </Pane>
        <Pane
          borderTop="default"
          padding={5}
          width="100%"
          justifyContent="space-between"
          alignItems="center"
          display="flex"
        >
          {footer || (
            <>
              {onNext || onBack ? (
                <Button appearance="minimal" onClick={onNext || onBack}>
                  {t(onNext ? 'other' : 'back')}
                </Button>
              ) : (
                <Pane />
              )}
              <Button
                appearance="minimal"
                intent="info"
                onClick={() => {
                  const vcard = vCardJS();
                  vcard.firstName = fullName;
                  vcard.organization = company;
                  vcard.email = email;
                  vcard.cellPhone = phone;
                  vcard.url = `${window.location.origin}/${id}`;

                  vcard.photo.embedFromString(avatar, 'image/png');

                  saveAs(
                    new Blob([vcard.getFormattedString()], {
                      type: 'text/vcard',
                    }),
                    `${id}.vcf`
                  );
                }}
              >
                {t('save')}
              </Button>
            </>
          )}
        </Pane>
      </Pane>
    </Pane>
  );
}

Info.propTypes = {
  onNext: PropTypes.func,
  onBack: PropTypes.func,
  id: PropTypes.string.isRequired,
  fullName: PropTypes.string,
  title: PropTypes.string,
  company: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  avatar: PropTypes.string,
  cover: PropTypes.string,
  headline: PropTypes.string,
  socials: PropTypes.object,
  footer: PropTypes.node,
};

Info.defaultProps = {
  onNext: undefined,
  onBack: undefined,
  fullName: '',
  title: '',
  company: '',
  email: '',
  avatar: '',
  cover: '',
  phone: '',
  headline: '',
  socials: {},
  footer: null,
};

export default Info;
