import { Avatar, Heading, IconButton, Link, LogOutIcon, Pane, Select } from 'evergreen-ui';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router-dom';

import { RESOURCES } from '../../config';
import { handleUserSignOut } from '../../redux/actions/user';

function AppBar() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ query: '(max-width: 500px)' });
  const { email, photoURL, displayName } = useSelector(({ user }) => user);

  const [active, setActive] = useState('');

  if (!email) return null;

  history.listen((location) => {
    setActive(location.pathname.split('/')?.[1]);
  });

  const navigate = (menu) => {
    setActive(menu);
    history.push(`/${menu}`);
  };

  return (
    <Pane
      display="flex"
      top={0}
      background="white"
      width="100%"
      height={64}
      position="sticky"
      zIndex={10}
      boxSizing="border-box"
      alignItems="center"
      paddingLeft={10}
      paddingRight={10}
      borderBottom="default"
    >
      <Pane display="flex" alignItems="center" width={200}>
        <Pane onClick={() => history.push('/')}>
          <img style={{ width: 50, height: 'auto' }} src={`${RESOURCES}/inbot.png`} alt="logo" />
        </Pane>
        {isMobile ? (
          <Select value={active} onChange={(e) => navigate(e.target.value)}>
            {['cards', 'signatures', 'backgrounds'].map((menu) => (
              <option key={menu} value={menu}>
                {t(`menu.${menu}`)}
              </option>
            ))}
          </Select>
        ) : (
          <Heading size={600}>{t(`menu.${active}`).replace('menu.', '')}</Heading>
        )}
      </Pane>
      {!isMobile
        ? ['cards', 'signatures', 'backgrounds'].map((menu) => (
            <Link
              key={menu}
              cursor="pointer"
              color={menu === active ? 'green' : 'neutral'}
              marginLeft={20}
              onClick={() => navigate(menu)}
            >
              {t(`menu.${menu}`)}
            </Link>
          ))
        : null}
      <Pane flexGrow={1} display="flex" alignItems="center" justifyContent="end">
        <Avatar name={displayName || email} src={photoURL || ''} size={40} />
        <IconButton
          icon={LogOutIcon}
          marginRight={10}
          marginLeft={10}
          appearance="minimal"
          onClick={() => {
            dispatch(handleUserSignOut());
            history.push('/welcome');
          }}
        />
      </Pane>
    </Pane>
  );
}

export default AppBar;
