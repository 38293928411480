import { combineReducers } from 'redux';

import cards from './cards';
import settings from './settings';
import user from './user';

const rootReducer = combineReducers({
  settings,
  user,
  cards,
});

export default rootReducer;
