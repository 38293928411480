import { IconButton, Pane, PlusIcon } from 'evergreen-ui';
import { difference } from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';

import { SOCIALS } from '../../../../config';
import Add from './Add';
import Item from './Item';
import Link from './Link';

function Socials({ onChange, socials }) {
  const available = useMemo(
    () => difference(Object.keys(SOCIALS), Object.keys(socials)),
    [socials]
  );

  const [addSocial, setAddSocial] = useState();
  const [changeLink, setChangeLink] = useState('');

  return (
    <Pane display="block">
      {changeLink ? (
        <Link
          social={changeLink}
          value={socials[changeLink]}
          onClose={() => setChangeLink('')}
          onChange={(val) => onChange(changeLink, val)}
        />
      ) : null}
      {addSocial ? (
        <Add
          onClose={() => setAddSocial()}
          onChange={(social, link) => onChange(social, link)}
          options={available}
        />
      ) : null}
      {Object.entries(socials).map(([id, link]) => (
        <Item key={id} social={id} link={link} onClick={() => setChangeLink(id)} />
      ))}
      {available.length ? (
        <IconButton
          icon={PlusIcon}
          float="left"
          padding={5}
          margin={5}
          onClick={() => setAddSocial({})}
        />
      ) : null}
    </Pane>
  );
}

Socials.propTypes = {
  onChange: PropTypes.func.isRequired,
  socials: PropTypes.shape({
    facebook: PropTypes.string,
    twitter: PropTypes.string,
    youtube: PropTypes.string,
    linkedin: PropTypes.string,
    instagram: PropTypes.string,
    telegram: PropTypes.string,
    discord: PropTypes.string,
    whatsapp: PropTypes.string,
    snapchat: PropTypes.string,
    yahoo: PropTypes.string,
  }),
};

Socials.defaultProps = {
  socials: {},
};

export default Socials;
