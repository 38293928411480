import { Pane } from 'evergreen-ui';
import PropTypes from 'prop-types';
import React from 'react';

import { getSocialsIcon } from '../../../Icons';

function Item({ onClick, social }) {
  return (
    <Pane
      float="left"
      display="flex"
      flexDirection="column"
      alignItems="center"
      padding={5}
      margin={5}
      borderRadius={50}
      hoverElevation={3}
      onClick={onClick}
    >
      {getSocialsIcon(social)}
    </Pane>
  );
}

Item.propTypes = {
  onClick: PropTypes.func.isRequired,
  social: PropTypes.string.isRequired,
};

export default Item;
