import { Avatar, toaster } from 'evergreen-ui';
import PropTypes from 'prop-types';
import React from 'react';
import Dropzone from 'react-dropzone';
import { useTranslation } from 'react-i18next';

function CustomAvatar({ avatar, onChange }) {
  const { t } = useTranslation('');

  return (
    <Dropzone
      multiple={false}
      onDrop={(files) => {
        if (files.length === 0) return;
        try {
          const reader = new FileReader();
          reader.onerror = ({ message }) => toaster.error(message);
          reader.onload = () => onChange(reader.result);
          reader.readAsDataURL(files[0]);
        } catch {
          toaster.warning(t('warnings.invalidfile'));
        }
      }}
    >
      {({ getRootProps, getInputProps }) => (
        <div {...getRootProps()} style={{ width: 80, marginTop: -40 }}>
          <input {...getInputProps()} />
          <Avatar src={avatar} color="blue" size={80} />
        </div>
      )}
    </Dropzone>
  );
}

CustomAvatar.propTypes = {
  avatar: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

CustomAvatar.defaultProps = {
  avatar: '',
};

export default CustomAvatar;
