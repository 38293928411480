import { Button, Heading, Pane } from 'evergreen-ui';
import Parser from 'html-react-parser';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

function Viewer({ onNext, onBack, headline, content, footer }) {
  const { t } = useTranslation('', { keyPrefix: 'card' });

  const footerContent = useMemo(() => {
    if (footer) return footer;
    if (onNext || onBack) {
      return (
        <Button appearance="minimal" onClick={onNext || onBack}>
          {t(onNext ? 'other' : 'back')}
        </Button>
      );
    }
    return null;
  }, [footer, onBack, onNext]);

  return (
    <Pane
      display="flex"
      flexDirection="column"
      border="default"
      borderRadius={20}
      width={300}
      height={380}
    >
      <Pane display="flex" alignItems="center" flexDirection="column" borderBottom="default">
        <Heading size={400} padding={10}>
          {headline}
        </Heading>
      </Pane>
      <Pane flexGrow={1} padding={5}>
        {Parser(content)}
      </Pane>
      {footerContent ? (
        <Pane
          borderTop="default"
          padding={5}
          width="100%"
          justifyContent="space-between"
          alignItems="center"
          display="flex"
        >
          {footerContent}
        </Pane>
      ) : null}
    </Pane>
  );
}

Viewer.propTypes = {
  onNext: PropTypes.func,
  onBack: PropTypes.func,
  headline: PropTypes.string,
  content: PropTypes.string,
  footer: PropTypes.node,
};

Viewer.defaultProps = {
  onNext: undefined,
  onBack: undefined,
  headline: '',
  content: '',
  footer: null,
};

export default Viewer;
