import { Pane } from 'evergreen-ui';
import PropTypes from 'prop-types';
import React from 'react';

import Avatar from './Avatar';
import Cover from './Cover';
import Info from './Info';
import Socials from './Socials';

function CardEditor({ data, onChange }) {
  return (
    <Pane
      marginTop={10}
      width="100%"
      display="flex"
      justifyContent="center"
      height={Math.min(window.innerHeight - 250, 530)}
      overflowY="scroll"
    >
      <Pane display="flex" flexDirection="column" width={300} alignItems="center">
        <Cover cover={data.cover} onChange={(val) => onChange(['cover'], val)} />
        <Avatar avatar={data.avatar} onChange={(val) => onChange(['avatar'], val)} />
        <Info
          onChange={(field, val) => onChange([field], val)}
          fullName={data.fullName}
          title={data.title}
          company={data.company}
          email={data.email}
          phone={data.phone}
        />
        <Socials
          onChange={(social, link) => onChange(['socials', social], link)}
          socials={data.socials}
        />
      </Pane>
    </Pane>
  );
}

CardEditor.propTypes = {
  onChange: PropTypes.func.isRequired,
  data: PropTypes.shape({
    fullName: PropTypes.string,
    cover: PropTypes.string,
    avatar: PropTypes.string,
    title: PropTypes.string,
    company: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    socials: PropTypes.object,
  }).isRequired,
};

export default CardEditor;
