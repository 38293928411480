import { Dialog, Pane, TextInputField } from 'evergreen-ui';
import { capitalize } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getSocialsIcon } from '../../../Icons';

function Link({ onClose, onChange, social, value: initValue }) {
  const { t } = useTranslation('');
  const [value, setValue] = useState(initValue);

  return (
    <Dialog
      isShown
      shouldCloseOnOverlayClick={false}
      title={
        <Pane display="flex">
          {getSocialsIcon(social)}
          &nbsp;
          {capitalize(social)}
        </Pane>
      }
      onCloseComplete={() => onClose()}
      confirmLabel={t('common.apply')}
      cancelLabel={t('common.remove')}
      onConfirm={(close) => {
        onChange(value);
        close();
      }}
      onCancel={(close) => {
        onChange('');
        close();
      }}
    >
      <TextInputField
        label="Link"
        hint={t('common.cleartoremove')}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
    </Dialog>
  );
}

Link.propTypes = {
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  social: PropTypes.string.isRequired,
  value: PropTypes.string,
};

Link.defaultProps = {
  value: '',
};

export default Link;
